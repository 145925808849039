import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ToastService} from './toast.service';
import {tap} from 'rxjs';
import {AlertTypes, Toaster} from "./toast.model";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgClass} from "@angular/common";
import {IumIconExclamationTriangle} from "../../ium-svg-icons/ium-icon-exclamation-triangle";
import {IumIconCross} from "../../ium-svg-icons/ium-icon-cross";
import {IumIconPersonComponent} from "../../ium-svg-icons/ium-icon-person.component";
import {IumIconCheck} from "../../ium-svg-icons/ium-icon-check.component";
import {IumIconEnvelopeClosedComponent} from "../../ium-svg-icons/ium-icon-envelope-closed.component";

@Component({
  selector: 'ium-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [
    NgClass,
    IumIconExclamationTriangle,
    IumIconCross,
    IumIconPersonComponent,
    IumIconCheck,
    IumIconEnvelopeClosedComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

  protected readonly AlertTypes = AlertTypes;

  toasters: Toaster[] = [];
  readonly TIMEOUT = 5000;

  constructor(
    private readonly _toast: ToastService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._toast.toaster$
      .pipe(
        tap(result => {
          result.id = new Date().getTime();
          if (result?.alertType == null) {
            return;
          }
          this.toasters.push(result);

          setTimeout(() => {
            this.onClose(result);
          }, this.TIMEOUT);

          this._cdr.detectChanges();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  onClose(toaster: Toaster) {
    this.toasters = this.toasters.filter(t => t.id !== toaster.id);
    this._cdr.detectChanges();
  }

}
