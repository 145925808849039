import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, filter, lastValueFrom, map, Observable, of, shareReplay} from 'rxjs';
import {IumRoleGroups} from "../app-types/app-types.model";

const ANONYMOUS: Session = null;
const CACHE_SIZE = 1;

export interface Claim {
  type: string;
  value: string;
}

export type Session = Claim[] | null;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private session$: Observable<Session> | null = null

  constructor(
    private readonly _http: HttpClient
  ) {
  }

  getSession(ignoreCache: boolean = false) {
    if (!this.session$ || ignoreCache) {
      this.session$ = this._http.get<Session>('bff/user').pipe(
        catchError(err => {
          return of(ANONYMOUS);
        }),
        shareReplay(CACHE_SIZE)
      );
    }
    return this.session$;
  }

  getIsAuthenticated(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(UserIsAuthenticated)
    );
  }

  getIsAnonymous(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(UserIsAnonymous)
    );
  }

  getUsername(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'name')?.value)
    );
  }

  getLogoutUrl(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'bff:logout_url')?.value)
    );
  }

  isSuperAdmin(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(IsSuperAdmin)
    );
  }

  IsAgency(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(IsAgency)
    );
  }

  isTenant(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(isTenant)
    );
  }

  async isLoggedIn(ignoreCache: boolean = false): Promise<boolean> {
    return await lastValueFrom(this.getIsAuthenticated(ignoreCache));
  }
}

const UserIsAuthenticated = (s: Session): s is Claim[] => {
  return s !== null;
}

const UserIsAnonymous = (s: Session): s is null => {
  return s === null;
}

const IsSuperAdmin = (s: Session): s is null => {
  const role = s?.find(c => c.type === 'role')?.value.toLowerCase() ?? '';
  return IumRoleGroups.SuperAdmin.includes(role);
}

const IsAgency = (s: Session): s is null => {
  const role = s?.find(c => c.type === 'role')?.value.toLowerCase() ?? '';
  return IumRoleGroups.AgencyAdmin.includes(role);
}

const isTenant = (s: Session): s is null => {
  const role = s?.find(c => c.type === 'role')?.value.toLowerCase() ?? '';
  return IumRoleGroups.TenantAdmin.includes(role);
}
