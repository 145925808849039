export enum AlertTypes {
  Activated,
  Deactivated,
  Success,
  Invite
}

export class Toaster {
  alertType?: AlertTypes;
  title?: string;
  description?: string;
  show?: boolean = false;
  id?: number;
}

export class ToastOptions {
  alertType?: AlertTypes;
  delay?: number;
}
