import {OrderType} from "../ui-components/column-sorting/column-sorting.component";
import {Observable} from "rxjs";

export interface ISelectItem<T> {
  label: string;
  value: T;
  selected?: boolean;
  disabled?: boolean;
}

export interface IDictionary<T> {
  [key: string | number]: T;
}

export interface ITime {
  hours: number;
  minutes: number;
}

export type TimeSelectItem = ISelectItem<ITime>;

export enum LcApplication {
  REI = 'REI',
  LAI = 'LAI',
  TrustCo = 'TrustCo',
  CustomerPortal = 'CustomerPortal'
}

export interface IPagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface IUmResponse<T> {
  result: T[];
  pagination: IPagination;
}

export interface IUserManager {
  id?: string | number;
  tenantId?: string;
  name: string;
  email: string;
  role: string;
}

export interface IBaseManagementService {
  getAll<T>(
    pageNumber: number,
    pageSize: number,
    searchQuery?: string,
    sortingParameters?: IDictionary<OrderType>
  ): Observable<IUmResponse<T>>;

  getById<T>(id: string | number): Observable<T>;

  activate(id: string | number): Observable<void>;

  deactivate(id: string | number): Observable<void>;
}

export enum UserRoles {
  SuperAdmin = 'super-admin',
  AgencyAdmin = 'agency-admin',
  Agent = 'agent',
  UserManagementTenantAdmin = 'um-tenant-admin',
  TrustCoTenantAdmin = 'tc-tenant-admin',
  TrustCoContentEditor = 'tc-content-editor',
  TrustCoContentPublisher = 'tc-content-publisher',
  CustomerPortalTenantAdmin = 'cp-tenant-admin',
  CustomerPortalUser = 'cp-user'
}

export class UserRoleNames {
  static [UserRoles.SuperAdmin] = 'Super Admin';
  static [UserRoles.AgencyAdmin] = 'Agency Admin';
  static [UserRoles.Agent] = 'Agent';
  static [UserRoles.UserManagementTenantAdmin] = 'Tenant Admin';
  static [UserRoles.TrustCoTenantAdmin] = 'Tenant Admin';
  static [UserRoles.TrustCoContentEditor] = 'Content Editor';
  static [UserRoles.TrustCoContentPublisher] = 'Content Publisher';
  static [UserRoles.CustomerPortalTenantAdmin] = 'Tenant Admin';
  static [UserRoles.CustomerPortalUser] = 'User';
}


export class IumRoleGroups {
  static SuperAdmin: string[] = [
    UserRoles.SuperAdmin
  ];
  static AgencyAdmin: string[] = [
    UserRoles.AgencyAdmin
  ];
  static AgencyNonAdmin: string[] = [
    UserRoles.Agent
  ];
  static TenantAdmin: string[] = [
    UserRoles.UserManagementTenantAdmin
  ];
}
