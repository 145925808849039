<div class="ium-app-page">
  <ium-header></ium-header>

  <div class="ium-app-container">
    <router-outlet></router-outlet>
  </div>

  <uim-footer></uim-footer>

  <ium-toast></ium-toast>
</div>
