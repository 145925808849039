import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {HeaderComponent} from "./header/header.component";
import {NgxSpinnerComponent} from "ngx-spinner";
import {ToastComponent} from "../shared/ui-components/toast/toast.component";
import {FooterComponent} from "./footer/footer.component";

@Component({
  selector: 'ium-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    NgxSpinnerComponent,
    ToastComponent,
    FooterComponent
  ]
})
export class AppComponent {
}
