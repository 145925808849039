import {Component} from '@angular/core';
import {AsyncPipe, Location, NgClass, NgIf, NgOptimizedImage, UpperCasePipe} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {AppConfigService} from "../../shared/services/app-config.service";
import {catchError, EMPTY, tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ClickOutsideDirective} from "../../shared/directives/click-outside.directive";
import {IumIconLogoutComponent} from "../../shared/ium-svg-icons/ium-icon-logout.component";
import {IumIconChevronDownComponent} from "../../shared/ium-svg-icons/ium-icon-chevron-down.component";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {ManagementService} from "../../shared/services/management.service";
import {UserManagerVm} from "../management/management.model";

@Component({
  selector: 'ium-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    RouterLinkActive,
    NgIf,
    AsyncPipe,
    NgOptimizedImage,
    ClickOutsideDirective,
    UpperCasePipe,
    IumIconLogoutComponent,
    IumIconChevronDownComponent
  ]
})
export class HeaderComponent {

  username$ = this._auth.getUsername();
  authenticated$ = this._auth.getIsAuthenticated();
  anonymous$ = this._auth.getIsAnonymous();
  logoutUrl$ = this._auth.getLogoutUrl();

  isBannerDisplayed = false;
  isUserMenuDisplayed = false;
  nameInitial = '';

  impersonatedUser: UserManagerVm | null = null;
  representedUser: UserManagerVm | null = null;

  constructor(
    protected readonly appConfig: AppConfigService,
    private readonly _auth: AuthenticationService,
    private readonly _location: Location,
    private readonly _managementService: ManagementService
  ) {
    _auth.getSession();

    this.username$
      .pipe(
        tap(result => {
          if (result != null) {
            const names = result.split(' ');
            this.nameInitial = names.length > 1
              ? names[0].substring(0, 1) + names[1].substring(0, 1)
              : result.substring(0, 2);
          }
        }),
        catchError(err => {
          console.log(err);
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    this.checkBanner();

    this.impersonatedUser = this._managementService.readImpersonatedUser() as UserManagerVm;
    this.representedUser = this._managementService.readRepresentedUser() as UserManagerVm;
    if (this.representedUser) {
      this.representedUser.nameInitial = UserManagerVm.getInitial(this.representedUser.agency);  // ToDo: fast solution
    }
  }

  onToggleUserMenu() {
    this.isUserMenuDisplayed = !this.isUserMenuDisplayed;
  }

  onReturnToSuperAdmin() {
    this._managementService.removeImpersonatedUser();
    window.location.reload();
  }

  onReturnToAgencyMode() {
    this._managementService.removeRepresentedUser();
    window.location.reload();
  }

  onLogout() {
    this._managementService.removeImpersonatedUser();
    this._managementService.removeRepresentedUser();
    this.onHideUserMenu();
  }

  onHideUserMenu() {
    this.isUserMenuDisplayed = false;
  }

  private checkBanner() {
    const path = this._location.path().split('/');
    if (path.length > 2) {
      const lastSegment = path[path.length - 1];
      const prevSegment = path[path.length - 2];
      this.isBannerDisplayed = prevSegment === 'campaign' && lastSegment?.length > 0;
    }
  }

}
