import {DateTime} from "luxon";
import {AgencyDto} from "../../shared/services/agencies.service";
import {ApplicationDto, TenantDto} from "../../shared/services/tenants.service";
import {IUserManager, LcApplication} from "../../shared/app-types/app-types.model";
import {SuperAdminDto} from "../../shared/services/super-admin.service";
import {UserDto} from "../../shared/services/users.service";

export class ApplicationVm {
  application!: LcApplication;
  enabled!: boolean;
  role!: string;

  static fromDto(dto: ApplicationDto) {
    const vm = new ApplicationVm();
    vm.application = dto?.application;
    vm.enabled = dto?.enabled ?? false;
    vm.role = dto?.role;

    return vm;
  }
}

export class TenantVm {
  tenantId!: number;
  companyEmail!: string;
  companyName!: string;
  agency!: string;
  createdDate!: DateTime;
  isActive: boolean = false;
  applications: ApplicationVm[] = [];
  agencyId!: number;
  clientPortal: boolean = false;
  trustCo: boolean = false;

  static fromDto(dto: TenantDto) {
    const vm = new TenantVm();
    vm.tenantId = dto?.tenantId;
    vm.companyEmail = dto?.companyEmail;
    vm.companyName = dto?.companyName;
    vm.agency = dto?.agency;
    vm.agencyId = dto?.agencyId;
    vm.createdDate = DateTime.fromISO(dto?.createdDate);
    vm.isActive = dto?.isActive ?? false;
    vm.applications = dto?.applications?.map(a => ApplicationVm.fromDto(a)) ?? [];
    vm.clientPortal = dto?.applications?.find(a => a.application === LcApplication.CustomerPortal)?.enabled ?? false;
    vm.trustCo = dto?.applications?.find(a => a.application === LcApplication.TrustCo)?.enabled ?? false;

    return vm;
  }
}

export class UserVm {
  userId!: string;
  email!: string;
  name!: string;
  isActive!: boolean;
  createdDate!: DateTime;
  applications: ApplicationVm[] = [];
  clientPortal!: boolean;
  trustCoPortal!: boolean;
  clientPortalRole!: string | null;
  trustCoPortalRole!: string | null;
  firstName!: string;
  lastName!: string;
  isUserManager!: boolean;

  static fromDto(dto: UserDto) {
    const vm = new UserVm();
    vm.userId = dto?.id;
    vm.name = dto?.name;
    vm.email = dto?.email;
    vm.isActive = dto?.isActive ?? false;
    vm.createdDate = DateTime.fromISO(dto?.createdDate);
    vm.applications = dto?.applications?.map(a => ApplicationVm.fromDto(a)) ?? [];
    vm.clientPortal = dto?.applications?.find(a => a.application === LcApplication.CustomerPortal)?.enabled ?? false;
    vm.trustCoPortal = dto?.applications?.find(a => a.application === LcApplication.TrustCo)?.enabled ?? false;
    vm.clientPortalRole = dto?.applications?.find(a => a.application === LcApplication.CustomerPortal)?.role ?? null;
    vm.trustCoPortalRole = dto?.applications?.find(a => a.application === LcApplication.TrustCo)?.role ?? null;
    vm.firstName = dto?.firstName;
    vm.lastName = dto?.lastName;
    vm.isUserManager = dto?.isUserManager ?? false;

    return vm;
  }
}

export class AgencyVm {
  agencyId!: number;
  name!: string;
  createdDate!: DateTime;
  isActive!: boolean;
  tenants!: TenantVm[];

  static fromDto(dto: AgencyDto) {
    const vm = new AgencyVm();
    vm.agencyId = dto?.agencyId;
    vm.name = dto?.name;
    vm.isActive = dto?.isActive ?? false;
    vm.createdDate = DateTime.fromISO(dto?.createdDate);
    vm.tenants = dto?.tenants?.map(t => TenantVm.fromDto(t)) ?? [];

    return vm;
  }
}

export class AgentVm {
  id!: string;
  name!: string;
  email!: string;
  isActive!: boolean;
  createdDate!: string;
  role!: string;
  firstName!: string;
  lastName!: string;

  static fromDto(dto: any) {
    const vm = new UserCommonVm();
    vm.id = dto?.id;
    vm.name = dto?.name;
    vm.email = dto?.email;
    vm.firstName = dto?.firstName;
    vm.lastName = dto?.lastName;
    vm.isActive = dto?.isActive ?? false;
    vm.createdDate = DateTime.fromISO(dto?.createdDate);
    vm.role = dto?.role;

    return vm;
  }
}

export class UserCommonVm {
  id!: string | number;
  name!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  createdDate!: DateTime;
  isActive!: boolean;
  role!: string | null;
  isUserManager!: boolean;
  trustCoPortal!: boolean;
  clientPortal!: boolean;
  trustCoPortalRole!: string | null;
  clientPortalRole!: string | null;

  static fromDto(dto: any) {
    const vm = new UserCommonVm();
    vm.id = dto?.id;
    vm.name = dto?.name;
    vm.firstName = dto?.firstName;
    vm.lastName = dto?.lastName;
    vm.email = dto?.email;
    vm.isActive = dto?.isActive ?? false;
    vm.createdDate = DateTime.fromISO(dto?.createdDate);
    vm.role = dto?.role;
    vm.isUserManager = dto?.isUserManager ?? false;
    vm.clientPortal = dto?.applications?.find((a: any) => a.application === LcApplication.CustomerPortal)?.enabled ?? false;
    vm.trustCoPortal = dto?.applications?.find((a: any) => a.application === LcApplication.TrustCo)?.enabled ?? false;
    vm.clientPortalRole = dto?.applications?.find((a: any) => a.application === LcApplication.CustomerPortal)?.role ?? null;
    vm.trustCoPortalRole = dto?.applications?.find((a: any) => a.application === LcApplication.TrustCo)?.role ?? null;

    return vm;
  }
}

export class SuperAdminVm {
  id!: string;
  name!: string;
  email!: string;
  createdDate!: DateTime;
  isActive!: boolean;
  firstName!: string;
  lastName!: string;

  static fromDto(dto: SuperAdminDto) {
    const vm = new SuperAdminVm();
    vm.id = dto?.id;
    vm.name = dto?.name;
    vm.email = dto?.email;
    vm.isActive = dto?.isActive ?? false;
    vm.createdDate = DateTime.fromISO(dto?.createdDate);
    vm.firstName = dto?.firstName;
    vm.lastName = dto?.lastName;

    return vm;
  }
}

export class UserManagerVm {
  id: string | number = '';
  name: string = '';
  email: string = '';
  role: string = '';
  nameInitial: string = '';
  asTenant: boolean | null = null;
  asAgency: boolean | null = null;
  agency: string = '';

  static fromDto(dto: IUserManager) {
    const vm = new UserManagerVm();
    vm.id = dto?.id ?? '';
    vm.name = dto?.name;
    vm.email = dto?.email;
    vm.role = dto?.role;
    vm.nameInitial = this.getInitial(vm.name);

    return vm;
  }

  static getInitial(name: string) {
    const names = name.split(' ');
    return names.length > 1
      ? names[0].substring(0, 1) + names[1].substring(0, 1)
      : name.substring(0, 2);
  }
}
