import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';

export const csrfHeaderInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  if (!request.headers.has("X-CSRF")) {
    request = request.clone({
      headers: request.headers.set("X-CSRF", "1"),
    });
  }

  return next(request);
}
