import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {PlatformLocation} from "@angular/common";
import {environment} from "../../environments/environment";
import {BehaviorSubject, lastValueFrom, tap} from "rxjs";

export class AppConfig {
  appBaseUrl: string | null = null;
  remoteServiceBaseUrl: string | null = null;

  constructor(param?: AppConfig) {
    if (param != null) {
      this.appBaseUrl = param.appBaseUrl ?? null;
      this.remoteServiceBaseUrl = param.remoteServiceBaseUrl ?? null;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private _config = new AppConfig();

  readonly config$ = new BehaviorSubject<AppConfig>(new AppConfig());

  constructor(
    private readonly _http: HttpClient,
    private readonly _platformLocation: PlatformLocation
  ) {
  }

  get config(): AppConfig {
    return this._config;
  }

  init(): Promise<any> {
    this._config = new AppConfig();

    const appBaseUrl = this.getDocumentOrigin() + this.getBaseHref();
    const url = appBaseUrl + 'assets/' + environment.appConfig;
    const request = this._http.get<any>(url)
      .pipe(
        tap(result => {
          if (result != null) {
            this._config.appBaseUrl = appBaseUrl;
            if (result.remoteServiceBaseUrl?.includes('{host}/')) {
              this._config.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace('{host}/', appBaseUrl);
            } else {
              this._config.remoteServiceBaseUrl = result.remoteServiceBaseUrl;
            }
            this.config$.next(this.config);
            console.log(this.config)
          }
        })
      );

    return lastValueFrom(request);
  }

  private getDocumentOrigin() {
    if (!document.location.origin) {
      return (
        document.location.protocol +
        '//' +
        document.location.hostname +
        (document.location.port ? ':' + document.location.port : '')
      );
    }

    return document.location.origin;
  }

  private getBaseHref(): string {
    const baseUrl = this._platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
      return baseUrl;
    }

    return '/';
  }

}
