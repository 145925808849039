import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AlertTypes, Toaster, ToastOptions} from "./toast.model";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toaster$ = new Subject<Toaster>();

  activated(message: string) {
    this.show(message, {
      alertType: AlertTypes.Activated
    });
  }

  deactivated(message: string) {
    this.show(message, {
      alertType: AlertTypes.Deactivated
    });
  }

  success(message: string) {
    this.show(message, {
      alertType: AlertTypes.Success
    });
  }

  invite(message: string) {
    this.show(message, {
      alertType: AlertTypes.Invite
    });
  }

  show(message: string, options?: ToastOptions) {
    this.toaster$.next({
      alertType: options?.alertType,
      description: message,
      show: true
    });
  }

}
