<div class="toaster-wrapper">
  @for (toaster of toasters; track toaster.id) {
    <div class="ium-toaster flex-middle"
         [ngClass]="{
        'toggle': toaster.show,
        'activated': toaster.alertType === AlertTypes.Activated,
        'deactivated': toaster.alertType === AlertTypes.Deactivated,
        'success': toaster.alertType === AlertTypes.Success,
        'invite': toaster.alertType === AlertTypes.Invite
    }">
      <div class="toaster-icon-container ium-mr-8 d-flex">
        @switch (toaster.alertType) {
          @case (AlertTypes.Activated) {
            <ium-icon-person></ium-icon-person>
          }
          @case (AlertTypes.Deactivated) {
            <ium-icon-person></ium-icon-person>
          }
          @case (AlertTypes.Success) {
            <ium-icon-check></ium-icon-check>
          }
          @case (AlertTypes.Invite) {
            <ium-icon-envelope-closed></ium-icon-envelope-closed>
          }
        }
      </div>

      <div class="flex-1">
        {{ toaster.description }}
      </div>

      <button class="btn ium-btn-close ium-btn-sm" (click)="onClose(toaster)">
        <ium-icon-cross></ium-icon-cross>
      </button>
    </div>
  }
</div>


