import {IumIconBaseComponent} from "./ium-icon-base";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'ium-icon-check',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle
  ],
  template: `
    <svg class="ium-svg-icon ium-svg-exclamation-check"
         [ngStyle]="cssStyles"
         [attr.width]="width"
         [attr.height]="height"
         [attr.viewBox]="viewBox"
         [attr.fill]="background"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.49978 1.37705C3.8421 1.37705 0.876953 4.34219 0.876953 7.99988C0.876953 11.6575 3.8421 14.6227 7.49978 14.6227C11.1574 14.6227 14.1226 11.6575 14.1226 7.99988C14.1226 4.34219 11.1574 1.37705 7.49978 1.37705ZM1.82695 7.99988C1.82695 4.86686 4.36677 2.32705 7.49978 2.32705C10.6328 2.32705 13.1726 4.86687 13.1726 7.99988C13.1726 11.1329 10.6328 13.6727 7.49978 13.6727C4.36677 13.6727 1.82695 11.1329 1.82695 7.99988ZM10.1588 6.03775C10.3177 5.81191 10.2635 5.50001 10.0377 5.3411C9.81182 5.18218 9.49992 5.23642 9.341 5.46225L6.51964 9.47155L5.35668 8.28706C5.16322 8.09002 4.84665 8.08712 4.64961 8.28058C4.45256 8.47405 4.44966 8.79061 4.64312 8.98766L6.22646 10.6003C6.33042 10.7062 6.47605 10.7604 6.62395 10.7483C6.77185 10.7363 6.90674 10.6591 6.99214 10.5377L10.1588 6.03775Z"
            [attr.fill]="color"/>
    </svg>
  `
})
export class IumIconCheck extends IumIconBaseComponent {
}
