import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {

  constructor(private _elementRef: ElementRef) {
  }

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target', '$event.composedPath()'])
  @HostListener('document:touchstart', ['$event', '$event.target', '$event.composedPath()'])
  public onClick(event: MouseEvent, targetElement: HTMLElement, elementPath: Array<any>): void {
    if (!targetElement) {
      return;
    }

    const elementRefInPath = elementPath.find(e => e === this._elementRef.nativeElement);
    if (!elementRefInPath) {
      this.clickOutside.emit(event);
    }
  }

}
