import {HttpHandlerFn, HttpInterceptorFn, HttpParams, HttpRequest} from "@angular/common/http";
import {inject} from "@angular/core";
import {ManagementService} from "../shared/services/management.service";
import {AppConfigService} from "../shared/services/app-config.service";

export const impersonationInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const managementService = inject(ManagementService);
  const remoteServiceBaseUrl = inject(AppConfigService).config.remoteServiceBaseUrl;
  const baseUrl = inject(AppConfigService).config.appBaseUrl;

  const impUser = managementService.readImpersonatedUser();
  const noImpersonatedEndpoints = ["/user"];

  if (!impUser || !remoteServiceBaseUrl) {
    return next(request);
  }

  const url = !!remoteServiceBaseUrl && !!baseUrl
    ? remoteServiceBaseUrl.replace(baseUrl, '')
    : '';

  if (noImpersonatedEndpoints.some(u => {
    return request.url === (url + u);
  })) {
    return next(request);
  }

  const params = new HttpParams({
    fromObject: {
      'target-user-id': impUser.id ?? ''
    }
  });

  const modifiedRequest = request.clone({
    url: request.url.replace(remoteServiceBaseUrl, remoteServiceBaseUrl + '/impersonated'),
    params: params
  });

  return next(modifiedRequest);
}
