import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject, InjectionToken} from '@angular/core';
import {provideRouter} from '@angular/router';
import {CommonModule} from "@angular/common";
import {HttpClientModule, provideHttpClient, withInterceptors} from "@angular/common/http";
import {MAIN_ROUTES} from "./main.routes";
import {AppConfigService} from "./shared/services/app-config.service";
import {provideAnimations} from "@angular/platform-browser/animations";
import {impersonationInterceptor} from "./interceptors/impersonation.interceptor";
import {csrfHeaderInterceptor} from "./interceptors/csrf-header.interceptor";
import {representationInterceptor} from "./interceptors/representation.interceptor";

export const API_REMOTE_SERVICE_URL = new InjectionToken<string>("API_REMOTE_SERVICE_URL");

export function getRemoteServiceBaseUrl(): string | null {
  const appConfig = inject(AppConfigService);
  return appConfig.config.remoteServiceBaseUrl;
}

export function appInitializerFactory() {
  const appConfig = inject(AppConfigService);
  return () => appConfig.init();
}

export const mainConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(CommonModule),
    provideAnimations(),
    provideRouter(MAIN_ROUTES),
    provideHttpClient(
      withInterceptors([
        csrfHeaderInterceptor,
        impersonationInterceptor,
        representationInterceptor
      ])
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true
    },
    {
      provide: API_REMOTE_SERVICE_URL,
      useFactory: getRemoteServiceBaseUrl
    }
  ]
};
