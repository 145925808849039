import {Component, ElementRef, Input} from '@angular/core';

type CssStylesType = { [key: string]: number | string } | null | undefined;

@Component({
  template: '',
})
export abstract class IumIconBaseComponent {

  @Input() cssStyles: CssStylesType = null;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.display = 'inline-flex';
  }

  // --> it can be overridden for a specific icon

  get viewBox(): string {
    return '0 0 15 15';
  }

  get color(): string {
    return 'currentColor';
  }

  get width(): number | string {
    return 15;
  }

  get height(): number | string {
    return 15;
  }

  get background(): string {
    return 'none';
  }

  // <--

}
