import {IumIconBaseComponent} from "./ium-icon-base";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'ium-icon-envelope-closed',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle
  ],
  template: `
    <svg class="ium-svg-icon ium-svg-envelope-closed"
         [ngStyle]="cssStyles"
         [attr.width]="width"
         [attr.height]="height"
         [attr.viewBox]="viewBox"
         [attr.fill]="background"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.0332 2.16666C0.480918 2.16666 0.0332031 2.61437 0.0332031 3.16666V12.8333C0.0332031 13.3856 0.48092 13.8333 1.0332 13.8333H14.9665C15.5188 13.8333 15.9665 13.3856 15.9665 12.8333V3.16666C15.9665 2.61437 15.5188 2.16666 14.9665 2.16666H1.0332ZM1.0332 4.21786V3.16666H14.9665V4.21786C14.8733 4.21087 14.7771 4.23276 14.6921 4.28671L7.99987 8.53369L1.30766 4.28671C1.22265 4.23276 1.12644 4.21087 1.0332 4.21786ZM1.0332 5.17847V12.8333L14.9665 12.8333L14.9665 5.17847L8.24099 9.4466C8.09381 9.54001 7.90593 9.54001 7.75875 9.4466L1.0332 5.17847Z"
            [attr.fill]="color"/>
    </svg>
  `
})
export class IumIconEnvelopeClosedComponent extends IumIconBaseComponent {

  override get viewBox(): string {
    return '0 0 16 16';
  }

}
