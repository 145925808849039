import {IumIconBaseComponent} from "./ium-icon-base";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'ium-icon-person',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle
  ],
  template: `
    <svg class="ium-svg-icon ium-svg-exclamation-person"
         [ngStyle]="cssStyles"
         [attr.width]="width"
         [attr.height]="height"
         [attr.viewBox]="viewBox"
         [attr.fill]="background"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.50004 0.784973C5.61399 0.784973 4.08504 2.31392 4.08504 4.19997C4.08504 5.70646 5.06052 6.98511 6.41428 7.43875C5.35551 7.59074 4.44348 7.98318 3.74828 8.66831C2.82386 9.57934 2.3584 10.9333 2.3584 12.6933C2.3584 12.9556 2.57106 13.1683 2.8334 13.1683C3.09573 13.1683 3.3084 12.9556 3.3084 12.6933C3.3084 11.0932 3.72957 10.0206 4.41512 9.34494C5.10207 8.66794 6.13011 8.31496 7.5 8.31496C8.86988 8.31496 9.89797 8.66794 10.5849 9.34495C11.2705 10.0206 11.6917 11.0933 11.6917 12.6932C11.6917 12.9556 11.9043 13.1683 12.1667 13.1683C12.429 13.1683 12.6417 12.9556 12.6417 12.6933C12.6417 10.9333 12.1762 9.57934 11.2518 8.6683C10.5566 7.98319 9.64454 7.59075 8.58578 7.43876C9.93956 6.98513 10.915 5.70647 10.915 4.19997C10.915 2.31392 9.3861 0.784973 7.50004 0.784973ZM5.03504 4.19997C5.03504 2.83859 6.13866 1.73497 7.50004 1.73497C8.86143 1.73497 9.96504 2.83859 9.96504 4.19997C9.96504 5.56136 8.86143 6.66497 7.50004 6.66497C6.13866 6.66497 5.03504 5.56136 5.03504 4.19997Z"
            [attr.fill]="color"/>
    </svg>
  `
})
export class IumIconPersonComponent extends IumIconBaseComponent {
}
