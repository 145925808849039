import {IumIconBaseComponent} from "./ium-icon-base";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'ium-icon-cross',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgStyle
  ],
  template: `
    <svg class="ium-svg-icon ium-svg-exclamation-cross"
         [ngStyle]="cssStyles"
         [attr.width]="width"
         [attr.height]="height"
         [attr.viewBox]="viewBox"
         [attr.fill]="background"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.7814 4.53159C12.0059 4.30703 12.0059 3.94296 11.7814 3.71841C11.5568 3.49386 11.1928 3.49386 10.9682 3.71841L7.4998 7.18683L4.03139 3.71841C3.80684 3.49386 3.44277 3.49386 3.21822 3.71841C2.99367 3.94296 2.99367 4.30703 3.21822 4.53159L6.68663 8L3.21822 11.4684C2.99367 11.693 2.99367 12.057 3.21822 12.2816C3.44277 12.5061 3.80684 12.5061 4.03139 12.2816L7.4998 8.81317L10.9682 12.2816C11.1928 12.5061 11.5568 12.5061 11.7814 12.2816C12.0059 12.057 12.0059 11.693 11.7814 11.4684L8.31298 8L11.7814 4.53159Z"
            [attr.fill]="color"/>
    </svg>
  `
})
export class IumIconCross extends IumIconBaseComponent {
}
