import {Injectable} from "@angular/core";
import {IumRoleGroups, IUserManager} from "../app-types/app-types.model";
import {UserManagerVm} from "../../app/management/management.model";
import {map} from "rxjs";
import {AuthenticationService, Session} from "./authentication.service";

export enum ViewMode {
  SuperAdmin,
  AgencyAdmin,
  AgencyNonAdmin,
  TenantAdmin,
  User
}

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  IMPERSONATED_USER_STORAGE_KEY = "IumImpersonatedUser";
  REPRESENTED_USER_STORAGE_KEY = "IumRepresentedUser";

  constructor(
    private readonly _auth: AuthenticationService
  ) {
  }

  saveImpersonatedUser(user: IUserManager) {
    window.localStorage.setItem(
      this.IMPERSONATED_USER_STORAGE_KEY,
      JSON.stringify(user)
    );
  };

  readImpersonatedUser() {
    const result = window.localStorage.getItem(this.IMPERSONATED_USER_STORAGE_KEY);
    try {
      return result ? (JSON.parse(result) as IUserManager) : null;
    } catch (err) {
      window.localStorage.removeItem(this.IMPERSONATED_USER_STORAGE_KEY);
      return null;
    }
  };

  removeImpersonatedUser() {
    window.localStorage.removeItem(this.IMPERSONATED_USER_STORAGE_KEY);
  };

  saveRepresentedUser(user: IUserManager) {
    window.localStorage.setItem(this.REPRESENTED_USER_STORAGE_KEY, JSON.stringify(user));
  };

  readRepresentedUser() {
    const result = window.localStorage.getItem(this.REPRESENTED_USER_STORAGE_KEY);
    try {
      return result ? (JSON.parse(result) as IUserManager) : null;
    } catch (err) {
      window.localStorage.removeItem(this.REPRESENTED_USER_STORAGE_KEY);
      return null;
    }
  };

  removeRepresentedUser() {
    window.localStorage.removeItem(this.REPRESENTED_USER_STORAGE_KEY);
  };

  getViewMode(ignoreCache: boolean = false) {
    const impersonatedUser = this.readImpersonatedUser() as UserManagerVm;
    const representedUser = this.readRepresentedUser() as UserManagerVm;

    return this._auth.getSession(ignoreCache)
      .pipe(
        map((s: Session) => {
          const role = s?.find(c => c.type === 'role')?.value.toLowerCase() ?? null;
          if (role == null) {
            return ViewMode.User;
          }
          if (IumRoleGroups.SuperAdmin.includes(role)) {
            if (impersonatedUser?.asTenant) {
              return ViewMode.TenantAdmin;
            }
            if (impersonatedUser?.asAgency) {
              return ViewMode.AgencyAdmin;
            }
            return ViewMode.SuperAdmin;
          }
          if (IumRoleGroups.AgencyAdmin.includes(role)) {
            if (representedUser) {
              return ViewMode.TenantAdmin;
            }
            return ViewMode.AgencyAdmin;
          }
          if (IumRoleGroups.AgencyNonAdmin.includes(role)) {
            if (representedUser) {
              return ViewMode.TenantAdmin;
            }
            return ViewMode.AgencyNonAdmin;
          }
          if (IumRoleGroups.TenantAdmin.includes(role)) {
            return ViewMode.TenantAdmin;
          }
          return ViewMode.User;
        })
      )
  }

}
