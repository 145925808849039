<div>
  <header class="ium-header">
    <div>
      <img ngSrc="assets/images/logo.svg" alt="Launch Identity" priority height="32" width="209">
    </div>

    <div class="user-menu-container"
         (click)="onToggleUserMenu()"
         (clickOutside)="onHideUserMenu()">
      @if (authenticated$ | async) {
        <div class="d-flex align-items-center">
          @if (impersonatedUser) {
            <span class="ium-mr-8 text-default-color">Logged in as: </span>
            <div class="user-avatar">
              <span class="user-name flex-center">{{ impersonatedUser.nameInitial | uppercase }}</span>
            </div>
          } @else if (representedUser) {
            <span class="ium-mr-8 text-default-color">Representing Tenant {{ representedUser.id }}</span>
            <div class="user-avatar">
              <span class="user-name flex-center">{{ representedUser.nameInitial | uppercase }}</span>
            </div>
          } @else {
            <div class="user-avatar">
              <span class="user-name flex-center">{{ nameInitial | uppercase }}</span>
            </div>
          }
        </div>

        <div class="user-menu" [hidden]="!isUserMenuDisplayed">
          @if (impersonatedUser) {
            <a href="javascript:;" (click)="onReturnToSuperAdmin()">
              Return to Super Admin
            </a>
          }
          @if (representedUser) {
            <a href="javascript:;" (click)="onReturnToAgencyMode()">
              Return to Agency Mode
            </a>
          }
          @if (!representedUser) {
            <a href="{{logoutUrl$ | async}}" (click)="onLogout()">
              Logout
            </a>
          }
        </div>
      }
    </div>
  </header>
</div>



